<template>
    <b-card-body>                  
        <b-overlay
            :show="show"
            rounded="sm"
        >
            <b-row>
                <b-col md="3">
                    <b-form-group label="Domicile">
                        <v-select
                            :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                            "
                            label="label"
                            v-model="selected"
                            @input="onChangeDomicile(selected)"
                            :options = option
                        />
                    </b-form-group>
                </b-col>
                <b-col md="1">
                    <b-form-group label="# Active">
                        <b-form-input id="filterInput" disabled v-model="sumActiveTractors"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="1">
                    <b-form-group label="Active">
                        <b-form-checkbox switch size="lg" v-model="toggle" @input="onChangeToggle(toggle)"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <div class="tractor-list" style="width: 20%; flex: 0 0 20%;">
                    <b-card-code >
                        <div class="media-list media-bordered">
                            <b-media
                                style="cursor: pointer"
                                v-for="item in items"
                                :key="item.tractor_id"
                                @click="onChange(item.equipment_id)"
                            >
                                <h4 class="media-heading">
                                    <span v-if="highlightedTractor == item.equipment_id" class="text-black" style="font-size: 25px;">Tractor ID: {{ item.equipment_id }}</span>
                                    <span v-else>Tractor ID: {{ item.equipment_id }}</span>
                                </h4>
                                <div class="d-flex align-item-center">
                                    <b-icon v-if="item.ignition_status == 1" icon="key-fill" font-scale="1.5" variant="warning"></b-icon>
                                    <b-icon v-else icon="key-fill" font-scale="1.5" variant="secondary"></b-icon>
                                    &nbsp;
                                    {{ moment(item.event_time) }}
                                </div>
                                {{ item.speed }} mph
                                <b-icon v-if="item.speed !=0 && (item.heading == 0 || item.heading == 360)" icon="arrow-up" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading > 0 && item.heading < 90" icon="arrow-up-right" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading == 90" icon="arrow-left" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading > 90 && item.heading < 180" icon="arrow-down-left" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading == 180" icon="arrow-down" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading > 180 && item.heading < 270" icon="arrow-down-right" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading == 270" icon="arrow-left" font-scale="1.2" variant="danger"></b-icon>
                                <b-icon v-else-if="item.speed !=0 && item.heading > 270 && item.heading < 360" icon="arrow-up-left" font-scale="1.2" variant="danger"></b-icon>
                                &nbsp;
                                <span class="text-primary">{{ item.driver_name }}</span> <br>
                                {{ item.location }} {{item.state}}
                            </b-media>
                        </div>
                    </b-card-code>
                </div>
                <div style="width: 80%; flex: 0 0 80%;" id="map">
                    <MglMap
                        :accessToken="mapboxAccessToken"
                        :mapStyle.sync="mapStyle"
                        :center="coordinates"
                        :zoom="zoom"
                        :zoomControl="false"
                        @load="onMapLoad"
                    >   
                        <MglNavigationControl position="bottom-right" />
                        <MglMarker v-for="(l, key) in locations" 
                            :key="key" 
                            :coordinates="l.coordinates" 
                            color="blue" />
                    </MglMap>
                </div>
            </b-row>        
        </b-overlay>
    </b-card-body>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import BCardCode from "@core/components/b-card-code";
import { BCardBody, BRow, BCol, BMedia, BCardText, BOverlay, BButton, BFormGroup, BFormInput, BFormCheckbox } from "bootstrap-vue";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import { MglMap, MglMarker, MglNavigationControl, MglGeojsonLayer } from "vue-mapbox";
import { serverUri, mapBoxToken } from "@/config";
import { getUserData } from "@/auth/utils";

export default {
    components: {
        BCardCode,
        BMedia,
        BCardBody,
        BCardText,
        BRow,
        BCol,
        BOverlay,
        BButton,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        vSelect,
        MglMap,
        MglMarker,
        MglNavigationControl,
        MglGeojsonLayer,
        mapboxgl
    },
    data() {
        return {
            mapboxAccessToken: mapBoxToken,
            mapStyle: "mapbox://styles/mapbox/streets-v11",
            coordinates: [-96.708564, 32.657037],
            items: [],
            store: [],
            show: true,
            option: [],
            selected: "",
            locations: [],
            zoom: 5,
            sumActiveTractors: 0,
            toggle: false,
            highlightedTractor: null,
            mapSource: [],
            container: null,
        };
    },
    created() {
        axios
            .post(`${serverUri}/fleet/fetchvehiclelocation`, {
                company: getUserData().company_id,
            })
            .then((res) => {
                this.show = !this.show;
                this.items = res.data;
                this.store = res.data;
                this.locations = res.data.map((item) => {
                    return {
                        coordinates: [item.longitude, item.latitude],
                    }
                })
                this.sumActiveTractors = this.getSumActiveTractors(this.items);
            });

        axios
            .post(`${serverUri}/fleet/getfleet`, {
                company: getUserData().company_id,
            })
            .then((res) => {
                const that = this;
                const tempArr = [];
                res.data.map((item) => {
                    tempArr.push(item.domicile);
                });

                const unique = [...new Set(tempArr)];
                unique.forEach(function (item) {
                    that.option.push({ title: item, label: item });
                });
                
            });
    },
    methods: {
        onChange(value) {
            this.items.map((item) => {
                if (item.equipment_id == value) {
                    this.coordinates = [item.longitude, item.latitude];
                    this.zoom = 15;
                }
            });

        },
        onChangeDomicile() {
            this.onFilterTractors();
        },
        onChangeToggle() {
            this.onFilterTractors(); 
        },
        onFilterTractors() {
            if (!this.selected && this.toggle) this.items = this.store.filter(item => item.ignition_status == 1);
            else if (this.selected && this.toggle) this.items = this.store.filter(item => item.domicile == this.selected.title && item.ignition_status == 1);
            else if (this.selected && !this.toggle) this.items = this.store.filter(item => item.domicile == this.selected.title);
            else this.items = this.store;

            this.sumActiveTractors = this.getSumActiveTractors(this.items);
        },
        getSumActiveTractors(datas) {
            var sum = 0;
            datas.map((item) => {
                if (item.ignition_status == 1) sum++;
            });

            return sum;
        },
        async onMapLoad(e) {
            console.log(e);
            this.container = document.getElementsByClassName("tractor-list")[0];
            await axios
                .post(`${serverUri}/fleet/fetchvehiclelocation`, {
                    company: getUserData().company_id,
                })
                .then((res) => {
                    this.mapSource = res.data.map((item) => {
                        return {
                            'type': 'Feature',
                            'properties': {
                                'tractor': item.equipment_id
                                },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [item.longitude, item.latitude]
                                }
                        }
                    })
                })

            e.map.addSource('places', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.mapSource
                }
            });
           
            // Add a layer showing the places.
            e.map.addLayer({
                'id': 'places',
                'type': 'circle',
                'source': 'places',
                'paint': {
                    'circle-color': '#4264fb',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff'
                }
            });
            
            // Create a popup, but don't add it to the map yet.
            
            e.map.on('mouseenter', 'places', (e1) => {
                // Change the cursor style as a UI indicator.

                e.map.getCanvas().style.cursor = 'pointer';
                const coo = e1.features[0].geometry.coordinates.slice();
                const tractor = e1.features[0].properties.tractor;


                while (Math.abs(e1.lngLat.lng - coo[0]) > 180) {
                    coo[0] += e1.lngLat.lng > coo[0] ? 360 : -360;
                }

                this.highlightedTractor = tractor;

                var itemIndex = 0;

                this.items.forEach((item, i) => {
                    if (item.equipment_id == tractor) itemIndex = i;
                })

                setTimeout(() => {
                    var scrollTop = this.container.scrollTop;
                    this.container.scrollTo({
                        top: itemIndex * 160,
                        behavior: "smooth"
                    });
                }, 100); 
                
            })

            e.map.on('mouseleave', 'places', () => {
                // this.highlightedTractor = null;
            });
        },
        moment: function (date) {
            return moment(date).format('MM/DD/YYYY H:mm:ss');
        }
    },
};
</script>

<style lang="scss">
.vue2leaflet-map {
    &.leaflet-container {
        height: 350px;
    }
}

.tractor-list {
    position: relative; 
    overflow-y: scroll;
    height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 12.5rem ) );
}

</style>
